import { BaseIconPathStyle, BaseSvgStyle } from '../IconBaseStyle';
import { ComponentProps } from '../SVGBase';

function Mail({ size, color }: ComponentProps) {
  return (
    <BaseSvgStyle
      size={size}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <BaseIconPathStyle
        d="M1.5 4.75H22.5V19.75H1.5V4.75Z"
        stroke={color}
        strokeRound
      />
      <BaseIconPathStyle
        d="M22.1609 5.30005L14.0169 11.564C13.4386 12.0089 12.7295 12.2501 11.9999 12.2501C11.2703 12.2501 10.5611 12.0089 9.98287 11.564L1.83887 5.30005"
        stroke={color}
        strokeRound
      />
    </BaseSvgStyle>
  );
}

export default Mail;
